/* eslint-disable check-file/filename-blocklist */
import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	modalTitleEnabledForAllContent: {
		id: 'content-ownership.content-header.owner-modal.title-enabled-for-all-content',
		defaultMessage: 'Change owner',
		description: 'The title text for the owner modal',
	},
	modalBodyEnabledForAllContent: {
		id: 'content-ownership.content-header.owner-modal.body-enabled-for-all-content',
		defaultMessage: 'Choose a new owner',
		description: 'The main body text of the owner modal',
	},
	modalCurrentOwner: {
		id: 'content-ownership.content-header.owner-modal.current-owner',
		defaultMessage: 'Current owner',
		description: "Text in the modal before the current owner's name and photo",
	},
	modalCancel: {
		id: 'content-ownership.content-header.owner-modal.cancel-button',
		defaultMessage: 'Cancel',
		description: 'The text for the cancel button on the owner modal',
	},
	modalChangeOwner: {
		id: 'content-ownership.content-header.owner-modal.change-owner-button',
		defaultMessage: 'Change owner',
		description: 'The text for the change owner button on the owner modal',
	},
	confirmModalTitle: {
		id: 'content-ownership.content-header.confirm-owner-modal.title',
		defaultMessage: 'Confirm this ownership transfer?',
		description: 'The title text for the confirm owner modal',
	},
	confirmModalBody: {
		id: 'content-ownership.content-header.confirm-owner-modal.body',
		defaultMessage:
			'Once you transfer ownership of this page, only an admin or the new owner can reverse it. <button>Learn more</button>',
		description: 'The main body text of the confirm owner modal',
	},
	confirmModalCancel: {
		id: 'content-ownership.content-header.confirm-owner-modal.cancel-button',
		defaultMessage: 'Cancel',
		description: 'The text for the cancel button on the confirm owner modal',
	},
	confirmModalConfirmChangeOwner: {
		id: 'content-ownership.content-header.confirm-owner-modal.confirm-change-owner-button',
		defaultMessage: 'Continue',
		description: 'The text for the confirm change owner button on the confirm owner modal',
	},
	confirmModalCurrentOwner: {
		id: 'content-ownership.content-header.confirm-owner-modal.current-owner',
		defaultMessage: 'Current owner',
		description: 'The text for the current owner on the confirm owner modal',
	},
	confirmModalNewOwner: {
		id: 'content-ownership.content-header.confirm-owner-modal.new-owner',
		defaultMessage: 'New owner',
		description: 'The text for the new owner on the confirm owner modal',
	},
	bylineButtonTooltip: {
		id: 'content-ownership.byline-button.tooltip',
		defaultMessage: 'Manage page ownership',
		description:
			'The tooltip text for when a user who can change the page owner hovers over the byline button',
	},
	bylineButtonTooltipReadOnly: {
		id: 'content-ownership.byline-button.tooltip.read.only',
		defaultMessage: 'More details',
		description:
			'The tooltip text for when a user who cannot change the page owner hovers over the byline button',
	},
	popupTriggerLabel: {
		id: 'content-ownership.byline-popup.trigger-label',
		defaultMessage: 'Manage page ownership',
		description: 'The label text for byline trigger icon',
	},
	popupChangeOwnerButton: {
		id: 'content-ownership.byline-popup.change-owner-button',
		defaultMessage: 'Change owner',
		description: 'The text for the change owner button in the byline popup',
	},
	popupCreatedBy: {
		id: 'content-ownership.byline-popup.created-by',
		defaultMessage: 'Created by <b>{user}</b> on {date}',
		description: 'The text shown for the original creator in the byline popup',
	},
	popupCreatedByUnknownDate: {
		id: 'content-ownership.byline-popup.created-by.unknown-date',
		defaultMessage: 'Created by <b>{user}</b> on unknown date',
		description:
			'The text shown for the original creator in the byline popup but when the date is unknown',
	},
	popupLastOwnedBy: {
		id: 'content-ownership.byline-popup.last-owned-by',
		defaultMessage: 'Last owned by <b>{user}</b>',
		description: 'The text shown for the last owner of the page in the byline popup',
	},
	changeWarningTitle: {
		id: 'content-ownership.change-warning-dialog.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "This change can't be reversed",
		description: 'The title text for the change warning dialog',
	},
	changeWarningBody: {
		id: 'content-ownership.change-warning-dialog.body',
		defaultMessage:
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			"Once you transfer page ownership to someone else, you won't be able to reverse it.",
		description: 'The body text for the change warning dialog',
	},
	changeWarningCancel: {
		id: 'content-ownership.change-warning-dialog.cancel-button',
		defaultMessage: 'Cancel',
		description: 'The cancel button text for the change warning dialog',
	},
	changeWarningContinue: {
		id: 'content-ownership.change-warning-dialog.continue-button',
		defaultMessage: 'Continue',
		description: 'The continue button text for the change warning dialog',
	},
	bylineMainText: {
		id: 'content-ownership.byline.main-text',
		defaultMessage: 'Owned by {user}',
		description: "The main text that's shown for the content byline",
	},
	abbreviatedBylineMainText: {
		id: 'content-ownership.byline.abbreviated-main-text',
		defaultMessage: 'By {user}',
		description: "The abbreviated main text that's shown for the content byline",
	},
	bylineMainTextWithTemplate: {
		id: 'content-ownership.byline.main-text.with-template',
		defaultMessage: 'Owned by {user}, created {withTemplate}',
		description:
			"The main text that's shown for the content byline if the page was created with a template",
	},
	abbreviatedBylineMainTextWithTemplate: {
		id: 'content-ownership.byline.abbreviated-main-text.with-template',
		defaultMessage: 'By {user}, created {withTemplate}',
		description:
			"The abbreviated main text that's shown for the content byline if the page was created with a template",
	},
	ownerRestrictionTitle: {
		id: 'content-ownership.owner.restriction.title',
		defaultMessage: 'This person doesn’t have edit access to the page',
		description: 'Title warning that users do not have page access',
	},
	ownerRestrictionMessage: {
		id: 'content-ownership.owner.restriction.message',
		defaultMessage:
			'To grant them page ownership, you must first give them edit access to this page.',
		description: 'Message warning that users do not have page access',
	},
	manageRestrictionButton: {
		id: 'content-ownership.manage.restriction.button',
		defaultMessage: 'Manage page access',
		description: 'Link text to toggle restriction dialog',
	},
	ownerParentPageRestrictionTitle: {
		id: 'content-ownership.owner.parent.page.restriction.title',
		defaultMessage: 'This person doesn’t have edit access to this page and/or a parent page ',
		description: 'Title warning that users do not have page access',
	},
	ownerParentPageRestrictionMessage: {
		id: 'content-ownership.owner.parent.page.restriction.message',
		defaultMessage:
			'To grant them page ownership, you must provide them edit access to this and any necessary parent pages.',
		description: 'Message warning that users do not have page access',
	},
	manageParentPageRestrictionButton: {
		id: 'content-ownership.manage.parent.page.restriction.button',
		defaultMessage: 'Learn more',
		description: 'Link text to learn more about page restictions',
	},
	ownerPremiumRestrictionTitle: {
		id: 'content-ownership.owner.premium.restriction.title',
		defaultMessage: 'This person doesn’t have access to this page and/or a parent page ',
		description: 'Title warning that users do not have page access',
	},
	ownerPremiumRestrictionMessage: {
		id: 'content-ownership.owner.premium.restriction.message',
		defaultMessage:
			'To grant them page ownership, you must provide them edit access to this and any necessary parent pages.',
		description: 'Message warning that users do not have page access',
	},
	managePremiumRestrictionButton: {
		id: 'content-ownership.manage.premium.restriction.button',
		defaultMessage: 'Inspect permissions',
		description: 'Link text to toggle inspect permissions dialog',
	},
	ownerSpaceRestrictionTitle: {
		id: 'content-ownership.owner.space.restriction.title',
		defaultMessage: 'This person doesn’t have access to this space',
		description: 'Title warning that users do not have space access',
	},
	ownerSpaceRestrictionMessage: {
		id: 'content-ownership.owner.space.restriction.message',
		defaultMessage: 'In order to grant them page ownership, they must have access to this space.',
		description: 'Message warning that users do not have space access',
	},
	manageSpaceRestrictionButton: {
		id: 'content-ownership.manage.space.restriction.button',
		defaultMessage: 'Contact space admins',
		description: 'Link text to email space admin',
	},
	manageSpaceRestrictionButtonAdmin: {
		id: 'content-ownership.manage.space.restriction.button.admin',
		defaultMessage: 'Manage space permissions',
		description: 'Link text to go to edit space permissions page',
	},
	changeOwnerSpotlightTitle: {
		id: 'content-ownership.change-owner.spotlight.title',
		defaultMessage: 'Change who owns a page',
		description: 'The title text for the change owner onboarding spotlight',
	},
	changeOwnerSpotlightBody: {
		id: 'content-ownership.change-owner.spotlight.body',
		defaultMessage: 'You (or your admin) can now transfer ownership of a page to someone else.',
		description: 'The body text for the change owner onboarding spotlight',
	},
	changeOwnerSpotlightLearnMoreButton: {
		id: 'content-ownership.change-owner.spotlight.learn-more-button',
		defaultMessage: 'Learn more',
		description: 'The learn more button text for the change owner onboarding spotlight',
	},
	changeOwnerSpotlightOkButton: {
		id: 'content-ownership.change-owner.spotlight.ok-button',
		defaultMessage: 'OK',
		description: 'The OK button text for the change owner onboarding spotlight',
	},
	requestSpacePermissionAdminEmailSubject: {
		id: 'content-ownership.mailto.subject.space',
		defaultMessage: '[Confluence] A Confluence user needs space access',
		description:
			"Email subject for the mailto link used upon clicking the 'Contact space admins' link.",
	},
	requestSpacePermissionAdminEmailBody: {
		id: 'content-ownership.mailto.body.space',
		defaultMessage:
			// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
			// eslint-disable-next-line no-restricted-syntax
			'Hi,%0D%0A%0D%0AI have someone who needs access to a space, but neither of us can change their access level. Can you take a look?%0D%0A%0D%0AThanks!%0D%0A{currentUser}%0D%0A%0D%0AUser who needs access:%0D%0A{requestedUser}%0D%0A%0D%0ALink to permissions for space “{spaceName}”:%0D%0A{spacePermissionsUrl}%0D%0A%0D%0ALink to content "{pageTitle}":%0D%0A{pageUrl}',
		description:
			"Email body for the mailto link used upon clicking the 'Contact space admins' link.",
	},
	successFlagMessage: {
		id: 'content-ownership.change-owner.success-flag-message',
		defaultMessage: 'Ownership transferred to {user}',
		description:
			'Message in flag that notifies users that ownership of content has transferred to another user',
	},
	errorFlagMessage: {
		id: 'content-ownership.change-owner.error-flag-message',
		defaultMessage: 'Failed to transfer ownership',
		description: 'Message in flag that notifies users that transferring ownership has failed',
	},
	closeModal: {
		id: 'content-ownership.change-owner.close-modal-label',
		defaultMessage: 'Close modal',
		description: 'Label text for the close button on the change ownership dialog',
	},
	ownershipTransfer: {
		id: 'content-ownership.ownership-transfer.arrow-right-icon',
		defaultMessage: 'Ownership Transfer',
		description: 'Label text for the arrow right icon on the change ownership dialog',
	},
});
